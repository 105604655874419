<script>
/**
 * Revenue history component
 */
export default {
  data() {
    return {
      revenueData: [{
        marketplaces: "Themes Market",
        date: "Oct 15, 2018",
        tax: "$125.23",
        payout: "$5848.68",
        status: "Paid"
      },
        {
          marketplaces: "Freelance",
          date: "Oct 12, 2018",
          tax: "$78.03",
          payout: "$5848.68",
          status: "Paid"
        },
        {
          marketplaces: "Share Holding",
          date: "Oct 10, 2018",
          tax: "$358.24",
          payout: "$815.89",
          status: "Paid"
        },
        {
          marketplaces: "Envato's Affiliates",
          date: "Oct 03, 2018",
          tax: "$18.78",
          payout: "$248.75",
          status: "Paid"
        },
        {
          marketplaces: "Marketing Revenue",
          date: "Sep 21, 2018",
          tax: "$185.36",
          payout: "$978.21",
          status: "Paid"
        },
        {
          marketplaces: "Advertise Revenue",
          date: "Sep 15, 2018",
          tax: "$29.56",
          payout: "$358.10",
          status: "Paid"
        }, {
          marketplaces: "Envato's Affiliates",
          date: "Oct 03, 2018",
          tax: "$18.78",
          payout: "$248.75",
          status: "Paid"
        },
        {
          marketplaces: "Marketing Revenue",
          date: "Sep 21, 2018",
          tax: "$185.36",
          payout: "$978.21",
          status: "Paid"
        },
        {
          marketplaces: "Advertise Revenue",
          date: "Sep 15, 2018",
          tax: "$29.56",
          payout: "$358.10",
          status: "Paid"
        }
      ],
      loader:false,
    };
  },
  methods: {
    refreshStats() {
      this.loader = true;

      setTimeout(()=>{
        let currentIndex = this.revenueData.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [this.revenueData[currentIndex], this.revenueData[randomIndex]] = [
            this.revenueData[randomIndex], this.revenueData[currentIndex]];
        }
        this.loader = false;



        return this.revenueData;
      },2000)

    }
  }
};
</script>

<template>
  <b-overlay :show="loader" rounded="sm">

    <template #overlay>
      <div>
        <b-spinner small type="grow" variant="danger"></b-spinner>
        <b-spinner type="grow" variant="danger"></b-spinner>
        <b-spinner small type="grow" variant="danger"></b-spinner>
        <span class="sr-only text-dark">Please wait...</span>
      </div>
    </template>

    <div class="card">
      <div class="card-body">
        <div class="float-right" @click="refreshStats"><i class="fa fa-sync"/></div>
        <h4 class="header-title mb-3">Transaction Data</h4>
        <div class="table-responsive mb-0">
          <table class="table table-borderless table-hover table-centered m-0">
            <thead class="thead-light">
            <tr>
              <th>User</th>
              <th>Profit</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,i) in revenueData" :key="i">
              <td>
                <h5 class="m-0 font-weight-normal">{{ data.marketplaces }}</h5>
              </td>
              <td>{{ data.payout }}</td>
              <td>
                            <span :class="{
                    'bg-soft-success text-success': `${data.status}` === 'Paid',
                    'bg-soft-warning text-warning':
                      `${data.status}` === 'Upcoming',
                    'bg-soft-danger  text-danger':
                      `${data.status}` === 'Overdue'
                  }" class="badge">{{ data.status }}</span>
              </td>

            </tr>
            </tbody>
          </table>
        </div>
        <!-- end .table-responsive-->
      </div>
    </div>


  </b-overlay>
</template>

<style scoped>
#scroll-container {
  border: 3px solid black;
  border-radius: 5px;
  height: 100px;
  overflow: hidden;
}

#scroll-text {
  height: 100%;
  text-align: center;

  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 10s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
</style>
